import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { } = iceStarkData.store.get('commonAction')?.queryTools;
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
import { formatDate } from 'ucode-utils';
import enums from '@/assets/enum.json';
import './styles.scss';
const { tagstatusSelections } = iceStarkData.store.get('commonAction')?.links;
const TAGSTATUS = {
    SCRAP: "已报废",
    ACT: "（已激活）",
    REG: "（未激活）",
    LOTTERY: "（已抽奖）",
    NO_ACT: "（未激活）",
    DEAD: "（抽奖失效）",
    SCANED: "（已扫码）",
};

@connect(
    (state) => {
        return {
            codeInfo: state.queryTools.codeInfo,
            codeQuerySearch: state.queryTools.codeQuerySearch,
            savePrevquery: state.queryTools.savePrevquery,
            openIds: state.users.openIds,
            codeStatusSelector: state.links.tagstatusSelections || [],
        }
    },
    {
        tagstatusSelections: tagstatusSelections.REQUEST,
        setNavBar
    }
)

class CodeInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {

        // this.props.tagstatusSelections({ data: {}, status: 'ENABLE' });
        // 迎驾贡注释
        // this.props.tagstatusSelections({ data: {}, status: 'ENABLE' });
    }


    totag = (data) => {
        let a = [];
        if (data) {
            for (let x in data) {
                a.push(data[x].name + '(' + data[x].code + ')')
            };
        }
        return a.join(',')
    }

    toTagurlSlice = (url) => {
        const { openIds, codeQuerySearch, savePrevquery } = this.props;
        let newUrl = url;
        if (url && openIds.userType != 'SYSTEM' && codeQuerySearch.type == 2 && savePrevquery.codeQuery && /^\s*\d+\s*$/.test(savePrevquery.codeQuery)) {
            const endUrl = url.slice(-3);
            newUrl = '**********' + endUrl;
        }
        return newUrl;
    }
    componentDidMount() {
        // 迎驾贡注释
        // this.props.tagstatusSelections({ data: {}, status: 'ENABLE' });
    }
    render() {
        const { codeInfo, codeStatusSelector, codeQuerySearch = {}, savePrevquery } = this.props;
        return (
            <div className="code-info-box">
                {Object.keys(codeInfo).length == 0 ?
                    <div className="text-weak tip-text">输入码序号或码链接进行查询</div> : <div className="code-info">
                        <ul>
                            <li><span>码序号</span><span>{codeInfo.eseSeqnum}</span></li>
                            {/* <li><span>码状态</span><span>{codeInfo.registrationStatus ? enums.registrationStatus[codeInfo.registrationStatus] ? enums.registrationStatus[codeInfo.registrationStatus].name : '' : ''}</span></li>tagStatus */}
                            {/* <li><span>码状态</span><span>{TAGSTATUS[codeInfo.tagStatus] || '未入库'}</span></li> */}

                            <li><span>码状态</span><span>{(codeInfo.linkLastStatus ?
                                (codeStatusSelector.filter(key => key.code == codeInfo.linkLastStatus)[0] ?
                                    '环节码状态：' + codeStatusSelector.filter(key => key.code == codeInfo.linkLastStatus)[0].name + ' | C端扫码状态：' : '环节码状态：' + codeInfo.linkLastStatus + ' | C端扫码状态：') : 'C端扫码状态：')
                                + (TAGSTATUS[codeInfo.tagStatus] || '未入库')
                                + (' | B端扫码状态：' + (codeInfo.st ? TAGSTATUS[codeInfo.st] || '未入库' :
                                    codeInfo.tagStatus == 'SCANED' || codeInfo.tagStatus == 'LOTTERY' || codeInfo.tagStatus == 'DEAD' ? '已激活' : TAGSTATUS[codeInfo.tagStatus] || '未入库'))}</span></li>{/* tagStatus */}
                            <li><span>码标签</span><span>{this.totag(codeInfo.label)}</span></li>{/* tagStatus */}


                            {codeInfo.writeOffStatus && <li><span>核销状态</span><span>{enums.writeOffStatus[codeInfo.writeOffStatus]?.name}</span></li>}
                            {codeInfo.writeOffWordsName && <li><span>核销政策</span><span>{codeInfo.writeOffWordsName}{codeInfo.writeOffWordsId ? ` (${codeInfo.writeOffWordsId})` : ''}</span></li>}
                            {codeInfo.prizeName && <li><span>核销奖品</span><span>{codeInfo.prizeName}{codeInfo.prizeId ? ` (${codeInfo.prizeId})` : ''}</span></li>}
                            <li><span>码规格类型</span><span>{codeInfo.combinationTagType ? enums.combinationTagType[codeInfo.combinationTagType] ? enums.combinationTagType[codeInfo.combinationTagType].name : '' : ''}</span></li>

                            <li><span>码包名称</span><span>{codeInfo.packageName}{codeInfo.packageId ? ` (${codeInfo.packageId})` : ''}</span></li>

                            <li><span>所属批次</span><span>{codeInfo.batchName}{codeInfo.batchId ? ` (${codeInfo.batchId})` : ''}</span></li>
                            <li><span>产品</span><span>{codeInfo.productName}{codeInfo.productId ? ` (${codeInfo.productId})` : ''}</span></li>
                            <li><span>经销商</span><span>{codeInfo.franchiserName}{codeInfo.franchiserID ? ` (${codeInfo.franchiserID})` : ''}</span></li>
                            <li><span>成品灌装工厂</span><span>{codeInfo.productionFactryName}{codeInfo.productionFactryId ? ` (${codeInfo.productionFactryId})` : ''}</span></li>
                            {/* <li><span>激活方式</span><span>{codeInfo.activationMode}</span></li> */}
                            <li><span>码ID</span><span>{codeInfo.sysSeqnum}</span></li>
                            <li><span>码源</span><span>{codeInfo.codeSourceType ? enums.codeSourceType[codeInfo.codeSourceType] ? enums.codeSourceType[codeInfo.codeSourceType].name : '' : ''}</span></li>
                            <li><span>所属企业</span><span>{codeInfo.eseName}{codeInfo.eseId ? ` (${codeInfo.eseId})` : ''}</span></li>

                            <li><span>上级辅料箱序号</span><span>{codeInfo.tagParent ? codeInfo.tagParent.accessories : ''}</span></li>
                            <li><span>上级成品箱序号</span><span>{codeInfo.tagParent ? codeInfo.tagParent.product : ''}</span></li>
                        </ul>
                        <ul>

                            {savePrevquery.codeQuerySearch.type && savePrevquery.codeQuerySearch.type == 1 && <li><span>码链接</span><span></span>{this.toTagurlSlice(codeInfo.tagUrl)}</li>}
                            {savePrevquery.codeQuerySearch.type && savePrevquery.codeQuerySearch.type == 4 && <li><span>PIN码</span><span></span>{savePrevquery.codeQuery}</li>}
                            {codeInfo.combinationTagType == 'SINGLE_TAG' ?
                                <React.Fragment>
                                    <li><span>码印刷规格</span><span>{codeInfo.tagConfigName}{codeInfo.tagConfigId ? ` (${codeInfo.tagConfigId})` : ''}</span></li>
                                    <li><span>组合印刷规格</span></li>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <li><span>码印刷规格</span><span>{codeInfo.singleConfigNameOfSuit}{codeInfo.singleConfigIdOfSuit ? ` (${codeInfo.singleConfigIdOfSuit})` : ''}</span></li>
                                    <li><span>组合印刷规格</span><span>{codeInfo.tagConfigName}{codeInfo.tagConfigId ? ` (${codeInfo.tagConfigId})` : ''}</span></li>
                                </React.Fragment>
                            }
                            <li><span>码包生成时间</span><span>{formatDate(codeInfo.createTime)}</span></li>
                            <li><span>所属码段</span><span>{(codeInfo.eseSeqnumStart || codeInfo.eseSeqnumEnd) ? `${codeInfo.eseSeqnumStart}-${codeInfo.eseSeqnumEnd}` : ''} </span></li>
                            <li><span>包材印刷工厂</span><span>{codeInfo.printFactoryName}{codeInfo.printFactoryId ? ` (${codeInfo.printFactoryId})` : ''}</span></li>
                            <li><span>生产时间</span><span>{formatDate(codeInfo.productionTime)}</span></li>
                            <li><span>激活时间</span><span>{formatDate(codeInfo.activationTime)}</span></li>
                            <li><span>终端门店</span><span>{codeInfo.shopName}{codeInfo.shopID ? ` (${codeInfo.shopID})` : ''}</span></li>
                            <li><span>码用途</span><span>{codeInfo.purpose ? enums.purposeType[codeInfo.purpose] ? enums.purposeType[codeInfo.purpose].name : '' : ''}</span></li>

                            <li><span>下级辅料箱关联码量</span><span>{codeInfo.accessoriessChilrenNum}</span></li>
                            <li><span>下级成品箱关联码量</span><span>{codeInfo.productChilrenNum}</span></li>
                            <li><span>同级关联序号</span><span>{codeInfo.tagBrothers ? codeInfo.tagBrothers.join('、') : ''}</span></li>
                        </ul>
                    </div>}
            </div>
        )

    }
}

export default CodeInfo;